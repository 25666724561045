import {end} from '@jetCommon/api.js';
import AbsenceRequestApiCommon from '@jetCommon/api/absence-request';
import AciCarCostApiCommon from '@jetCommon/api/aci-car-cost.js';
import AuthApiCommon from '@jetCommon/api/auth.js';
import ClockingApi from '@/api/clocking.js';
import CompanyAbsenceKindCommon from '@jetCommon/api/company-absence-kind.js';
import CompanyApiCommon from '@jetCommon/api/company.js';
import CompanyBoardDocumentApiCommon from '@jetCommon/api/company-board-document.js';
import CompanyExpensePoliciesBaseApi from '@jetCommon/api/company-expense-policy.js';
import EmployeeApi from '@/api/employee.js';
import EmployeeDocumentApiCommon from '@jetCommon/api/employee-document.js';
import ExpenseApi from '@/api/expense.js';
import HiringApi from '@/api/hiring.js';
import HiringDocumentApi from '@/api/hiring-document.js';
import LocationApiCommon from '@jetCommon/api/location.js';
import ManagerApi from '@/api/manager.js';
import PayslipApiCommon from '@jetCommon/api/payslip.js';
import PersonAddressApiCommon from '@jetCommon/api/person-address.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import PersonPushTokensCommon from '@jetCommon/api/person-push-token.js';
import SafetyAndHealthApiCommon from '@jetCommon/api/safety-and-health';
import SetupApiCommon from '@jetCommon/api/setup.js';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles.js';

export const api = {
    end,
    absenceRequests: new AbsenceRequestApiCommon(),
    aciCarCosts: new AciCarCostApiCommon(),
    auth: new AuthApiCommon(),
    clocking: new ClockingApi(),
    companies: new CompanyApiCommon(),
    companyAbsenceKinds: new CompanyAbsenceKindCommon(),
    employeeDocuments: new EmployeeDocumentApiCommon(),
    companyBoardDocuments: new CompanyBoardDocumentApiCommon(),
    companyExpensePolicy: new CompanyExpensePoliciesBaseApi(),
    employees: new EmployeeApi(),
    expenses: new ExpenseApi(),
    hirings: new HiringApi(),
    hiringDocuments: new HiringDocumentApi(),
    locations: new LocationApiCommon(),
    managers: new ManagerApi(),
    payslips: new PayslipApiCommon(),
    persons: new PersonApiCommon(),
    personAddresses: new PersonAddressApiCommon(),
    personPushTokens: new PersonPushTokensCommon(),
    setup: new SetupApiCommon(),
    uploadedFiles: new UploadedFilesApiCommon(),
    safetyAndHealth: new SafetyAndHealthApiCommon(),
};
